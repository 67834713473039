import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('HeaderEventHandlingTable', _vm._g(_vm._b({
    ref: "table",
    staticClass: "selectable-table row-pointer d-flex flex-column",
    class: {
      enableHeaderContextMenu: _vm.enableHeaderContextMenu
    },
    attrs: {
      "headers": _vm.selectedHeaders
    },
    scopedSlots: _vm._u([_vm._l(_objectSpread(_objectSpread({}, _vm.$scopedSlots), {}, {
      top: undefined
    }), function (_, name) {
      return {
        key: name,
        fn: function fn(slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    }), {
      key: "top",
      fn: function fn(slotData) {
        return [_c('div', {
          staticClass: "p-relative"
        }, [_vm._t("top", null, null, slotData), _vm._v(" "), _c('ContextMenu', {
          ref: "menuRef",
          attrs: {
            "items": _vm.menuItems,
            "close-on-content-click": false,
            "icon-color": "primary"
          }
        }, [_c('template', {
          slot: "footer"
        }, [_c('TextButton', {
          staticClass: "mt-2",
          attrs: {
            "icon": "mdi-refresh",
            "value": _vm.$t('action.reset')
          },
          on: {
            "click": _vm.resetSelectedHeaders
          }
        })], 1)], 2), _vm._v(" "), _vm.enableHeaderContextMenu ? _c('div', {
          staticClass: "header-menu d-flex align-center"
        }, [_c('IconButton', {
          attrs: {
            "color": "secondary-text",
            "icon": "mdi-dots-vertical"
          },
          on: {
            "click": _vm.show
          }
        })], 1) : _vm._e()], 2)];
      }
    }], null, true)
  }, 'HeaderEventHandlingTable', _vm.attributes, false), _objectSpread(_objectSpread({}, _vm.$listeners), {}, {
    'header.contextmenu': _vm.enableHeaderContextMenu ? _vm.show : function () {}
  })), [_vm._l(_vm.$slots, function (_, name) {
    return _c('template', {
      slot: name
    }, [_vm._t(name)], 2);
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
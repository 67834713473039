import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('campaignList')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('nuxt-link', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "to": "/campaign/send"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.createCampaign')) + "\n    ")])], 1), _vm._v(" "), _c('ContentArea', [_c('Input', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('placeholder.keyword.campaigns')
    },
    model: {
      value: _vm.filterOptions.keywordText,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "keywordText", $$v);
      },
      expression: "filterOptions.keywordText"
    }
  }), _vm._v(" "), _c('CustomizableHeaderTable', {
    attrs: {
      "sort-desc": ['created_at'],
      "headers": [{
        text: 'キャンペーン名',
        value: 'name',
        multiLine: true,
        hideable: false
      }, {
        text: 'タッチ名',
        value: 'touches',
        multiLine: true
      }, {
        text: '到着希望日/発行日',
        value: 'shipping_date',
        transform: _vm.$date
      },
      // TODO APIの対応が必要 { text: '商品名', value: '', multiLine: true },
      // TODO APIの対応が必要 { text: '商品バリエーション', value: '', multiLine: true },
      // TODO APIの対応が必要 { text: '商品価格', value: '', align: 'center', multiLine: true },
      {
        text: '送付数',
        value: 'order_count',
        align: 'center',
        transform: function transform(val) {
          return _vm.$t('case', [val.toLocaleString()]);
        }
      }, {
        text: 'QR反響/クリック数',
        value: 'touched_count',
        align: 'center',
        getter: function getter(val) {
          var _val$touched_count, _val$lp_opened_count;
          return _vm.$t('case', [(_val$touched_count = val === null || val === void 0 ? void 0 : val.touched_count) !== null && _val$touched_count !== void 0 ? _val$touched_count : 0]) + '/' + _vm.$t('case', [(_val$lp_opened_count = val === null || val === void 0 ? void 0 : val.lp_opened_count) !== null && _val$lp_opened_count !== void 0 ? _val$lp_opened_count : 0]);
        }
      }, {
        text: 'コスト',
        value: 'total_cost',
        align: 'center',
        transform: _vm.$price
      }, {
        text: 'キャンペーン作成者',
        value: 'owner'
      }, {
        text: 'キャンペーン作成日',
        value: 'created_at',
        transform: _vm.$date
      }],
      "default-headers": ['キャンペーン名', '到着希望日/発行日', '送付数', 'QR反響/クリック数', 'コスト', 'キャンペーン作成者', 'キャンペーン作成日'],
      "items": _vm.datatable_items,
      "item-key": "id",
      "loading": _vm.loading,
      "options": _vm.tableOptions,
      "server-items-length": _vm.totalItems,
      "disable-sort": true
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "click:row": function clickRow(item, _, event) {
        return _vm.onClickItem(item, event);
      },
      "auxclick:row": function auxclickRow(event, row) {
        return _vm.onClickItem(row.item, event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.touches",
      fn: function fn(_ref) {
        var _item$line_item, _item$line_item2;
        var item = _ref.item;
        return [(_item$line_item = item.line_item) !== null && _item$line_item !== void 0 && _item$line_item.name ? _c('LinkText', {
          attrs: {
            "to": "/touch/".concat((_item$line_item2 = item.line_item) === null || _item$line_item2 === void 0 ? void 0 : _item$line_item2.id)
          }
        }, [_vm._v("\n          " + _vm._s(item.line_item.name) + "\n        ")]) : _vm._e()];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
/* unplugin-vue-components disabled */import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import SelectDisableableTable from './SelectDisableableTable.vue';
var HeaderEventHandlingTable = {
  extends: SelectDisableableTable,
  props: SelectDisableableTable.props,
  methods: {
    genHeaders: function genHeaders(props) {
      var _this = this;
      var headers = SelectDisableableTable.methods.genHeaders.call(this, props);
      headers.flat().filter(function (v) {
        return v === null || v === void 0 ? void 0 : v.data;
      }).forEach(function (header) {
        header.data.on = _objectSpread(_objectSpread({}, header.data.on), {}, {
          contextmenu: function contextmenu($event) {
            return _this.$emit('header.contextmenu', $event);
          }
        });
      });
      return headers;
    }
  }
};
export default {
  components: {
    HeaderEventHandlingTable: HeaderEventHandlingTable
  },
  props: _objectSpread(_objectSpread({}, HeaderEventHandlingTable.props), {}, {
    defaultHeaders: {
      type: Array,
      default: null
    },
    restoreKey: {
      type: String,
      default: function _default() {
        return this.$route.name;
      }
    },
    contextmenu: {
      type: Boolean,
      default: true
    }
  }),
  computed: {
    enableHeaderContextMenu: function enableHeaderContextMenu() {
      return this.contextmenu && !this.hideDefaultHeader;
    },
    selected: {
      get: function get() {
        var _this2 = this;
        var hideHeaders = useSettingsStore().getHideHeaders(this.restoreKey);
        if (hideHeaders) {
          return this.$options.propsData.headers.map(function (item) {
            return item.text;
          }).filter(function (item) {
            return !hideHeaders.includes(item);
          });
        } else {
          var hasNotHideable = this.$options.propsData.headers.some(function (item) {
            return item.hideable === false;
          });
          return this.$options.propsData.headers.filter(function (item, index) {
            var _this2$defaultHeaders;
            var notHideable = hasNotHideable ? item.hideable === false : index === 0;
            return notHideable || ((_this2$defaultHeaders = _this2.defaultHeaders) === null || _this2$defaultHeaders === void 0 ? void 0 : _this2$defaultHeaders.includes(item.text)) !== false;
          }).map(function (item) {
            return item.text;
          });
        }
      },
      set: function set(selected) {
        var hideHeaders = this.$options.propsData.headers.map(function (item) {
          return item.text;
        }).filter(function (item) {
          return !selected.includes(item);
        });
        useSettingsStore().updateHideHeaders(this.restoreKey, hideHeaders);
      }
    },
    attributes: function attributes() {
      return _objectSpread(_objectSpread(_objectSpread({
        footerProps: {
          'items-per-page-options': [10, 25, 50, 100]
        },
        mobileBreakpoint: 0
      }, this.$attrs), this.$options.propsData), {}, {
        headers: null
      });
    },
    selectedHeaders: function selectedHeaders() {
      var _this3 = this;
      return this.$options.propsData.headers.filter(function (item) {
        return _this3.selected.includes(item.text);
      });
    },
    menuItems: function menuItems() {
      var _this4 = this;
      var hasNotHideable = this.$options.propsData.headers.some(function (item) {
        return item.hideable === false;
      });
      return this.$options.propsData.headers.map(function (item, index) {
        var notHideable = hasNotHideable ? item.hideable === false : index === 0;
        var selected = _this4.selected.includes(item.text);
        return item.text ? {
          id: item.text,
          label: item.text,
          icon: selected ? notHideable ? 'mdi-minus' : 'mdi-check' : null,
          disabled: notHideable,
          onClick: function onClick() {
            if (_this4.selected.includes(item.text)) {
              _this4.selected = _this4.selected.filter(function (n) {
                return n !== item.text;
              });
            } else {
              _this4.selected = [].concat(_toConsumableArray(_this4.selected), [item.text]);
            }
          }
        } : null;
      }).filter(function (item) {
        return item != null;
      });
    }
  },
  methods: {
    show: function show(e) {
      var _this$$refs$menuRef;
      e.preventDefault();
      (_this$$refs$menuRef = this.$refs.menuRef) === null || _this$$refs$menuRef === void 0 ? void 0 : _this$$refs$menuRef.show(e);
    },
    resetSelectedHeaders: function resetSelectedHeaders() {
      useSettingsStore().updateHideHeaders(this.restoreKey, null);
    }
  }
};
/* unplugin-vue-components disabled */import "core-js/modules/es.symbol.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.for-each.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getSlot, getPrefixedScopedSlots, getObjectValueByPath } from 'vuetify/lib/util/helpers';
import { VDataTable } from 'vuetify/lib';
import CustomVDataFooter from './CustomVDataFooter.vue';
export default VDataTable.extend({
  methods: _objectSpread(_objectSpread({}, VDataTable.options.methods), {}, {
    genDefaultScopedSlot: function genDefaultScopedSlot(props) {
      var _this = this;
      var scopedSlots = getPrefixedScopedSlots('item.', this.$scopedSlots);
      this.computedHeaders.filter(function (h) {
        return !['data-table-select', 'data-table-expand'].includes(h.value);
      }).forEach(function (header) {
        var slotName = header.value;
        var originalSlot = scopedSlots[slotName];
        if ((originalSlot === null || originalSlot === void 0 ? void 0 : originalSlot._override) === true) return;
        _this.$scopedSlots["item.".concat(slotName)] = function (data) {
          var child = null;
          if (originalSlot) {
            var _child;
            child = originalSlot(data);
            // すべてtextノードですべて空文字だった場合はnullとして扱う
            if ((_child = child) !== null && _child !== void 0 && _child.every(function (node) {
              var _node$text;
              return ((_node$text = node.text) === null || _node$text === void 0 ? void 0 : _node$text.trim()) === '';
            })) child = null;
          } else if (typeof header.getter === 'function') {
            child = header.getter(data.item);
          } else {
            var value = getObjectValueByPath(data.item, header.value);
            if (typeof header.transform === 'function') {
              child = header.transform(value);
            } else if (value != null && String(value).trim() !== '') {
              child = String(value);
            }
          }
          if (child && header.ellipsis !== false) {
            if (header.multiLine) {
              // 複数行省略
              child = _this.$createElement('div', {
                directives: [{
                  modifiers: {
                    flexible: true
                  },
                  name: 'ellipsis',
                  value: 3
                }],
                style: {
                  'min-width': '100px',
                  'white-space': 'normal'
                }
              }, [child]);
            } else {
              // １行省略
              child = _this.$createElement('div', {
                directives: [{
                  name: 'ellipsis'
                }]
              }, [child]);
            }
          }
          if (child == null) {
            var _header$empty;
            return _this.$createElement('div', {
              staticClass: 'text-caption disabled-text--text'
            }, [(_header$empty = header.empty) !== null && _header$empty !== void 0 ? _header$empty : '-']);
          } else {
            return child;
          }
        };
        _this.$scopedSlots["item.".concat(slotName)]._override = true;
      });
      var row = VDataTable.options.methods.genDefaultScopedSlot.call(this, props);
      return row;
    },
    genFooters: function genFooters(props) {
      var data = {
        props: _objectSpread({
          options: props.options,
          pagination: props.pagination
        }, this.sanitizedFooterProps),
        on: {
          'update:options': function updateOptions(value) {
            return props.updateOptions(value);
          }
        },
        widths: this.widths,
        headers: this.computedHeaders
      };
      var children = [getSlot(this, 'footer', data, true)];
      if (!this.hideDefaultFooter) {
        children.push(this.$createElement(CustomVDataFooter, _objectSpread(_objectSpread({}, data), {}, {
          scopedSlots: getPrefixedScopedSlots('footer.', this.$scopedSlots)
        })));
      }
      return children;
    }
  })
});
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getObjectValueByPath } from 'vuetify/lib/util/helpers';
import { VSimpleCheckbox } from 'vuetify/lib';
import FloatingTooltip from '@/components/tooltip/FloatingTooltip.vue';
import StyledCellTable from './StyledCellTable.vue';
export default {
  extends: StyledCellTable,
  props: _objectSpread(_objectSpread({}, StyledCellTable.options.props), {}, {
    selectDisableKey: {
      type: [String, Function],
      default: 'isDisable'
    },
    selectDisableMessage: {
      type: [String, Function],
      default: null
    },
    selectProps: {
      type: Object,
      default: undefined
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.$on('update:page', function () {
      _this.$el.getBoundingClientRect().top < 0 && _this.$el.scrollIntoView({
        behavior: 'smooth'
      });
    });
  },
  methods: _objectSpread(_objectSpread({}, StyledCellTable.options.methods), {}, {
    isDisable: function isDisable(item) {
      return this.showSelect && (typeof this.selectDisableKey === 'function' ? !!this.selectDisableKey(item) : getObjectValueByPath(item, this.selectDisableKey) === true);
    },
    isSelectable: function isSelectable(item) {
      return StyledCellTable.options.methods.isSelectable.apply(this, arguments) && !this.isDisable(item);
    },
    genDefaultSimpleRow: function genDefaultSimpleRow(item, index) {
      var _this2 = this;
      var classes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var slot = this.$scopedSlots['item.data-table-select'];
      this.$scopedSlots['item.data-table-select'] = slot !== null && slot !== void 0 ? slot : function (data) {
        var _this2$checkboxColor, _this2$selectProps;
        var checkbox = _this2.$createElement(VSimpleCheckbox, {
          staticClass: 'v-data-table__checkbox',
          props: _objectSpread({
            value: data.isSelected,
            disabled: !_this2.isSelectable(data.item),
            color: (_this2$checkboxColor = _this2.checkboxColor) !== null && _this2$checkboxColor !== void 0 ? _this2$checkboxColor : ''
          }, (_this2$selectProps = _this2.selectProps) !== null && _this2$selectProps !== void 0 ? _this2$selectProps : {}),
          on: {
            input: function input(val) {
              return data.select(val);
            }
          }
        });
        if (_this2.isDisable(data.item) && _this2.selectDisableMessage) return _this2.$createElement(FloatingTooltip, {
          props: {
            top: true,
            maxWidth: 400
          },
          scopedSlots: {
            activator: function activator(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              checkbox.data.on = _objectSpread(_objectSpread({}, checkbox.data.on), on);
              checkbox.data.attrs = _objectSpread(_objectSpread({}, checkbox.data.attrs), attrs);
              return checkbox;
            }
          }
        }, [typeof _this2.selectDisableMessage === 'function' ? _this2.selectDisableMessage(data.item) : _this2.selectDisableMessage]);else return checkbox;
      };
      var disable = this.isDisable(item);
      var row = StyledCellTable.options.methods.genDefaultSimpleRow.call(this, item, index, _objectSpread(_objectSpread({}, classes), {}, {
        disable: disable
      }));
      return row;
    }
  })
};
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import Resize from 'vuetify/lib/directives/resize';

var render = function render() {
  var _vm$attributes$hideDe, _vm$attributes$placeh, _vm$attributes$cleara, _vm$attributes$hideDe2, _vm$attributes$placeh2, _vm$attributes$cleara2, _vm$attributes$hideDe3, _vm$attributes$placeh3, _vm$attributes$cleara3, _vm$attributes$hideDe4, _vm$attributes$hideDe5;
  var _vm = this,
    _c = _vm._self._c;
  return _c('FormHighlight', {
    staticClass: "input tw-relative tw-flex-[1_1_auto]",
    attrs: {
      "value": _vm.value,
      "initial-value": _vm.initialValue
    },
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.focus = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.focus = false;
      },
      "update:value": function updateValue($event) {
        return _vm.$emit('input', $event);
      }
    }
  }, [_vm.label ? _c('SmallLabel', {
    staticClass: "pb-1",
    attrs: {
      "label": _vm.label,
      "required": _vm.required
    }
  }, [_vm._t("label.prepend")], 2) : _vm._e(), _vm._v(" "), _c(VFadeTransition, {
    attrs: {
      "leave-absolute": ""
    }
  }, [_vm.focus && _vm.$listeners.contextmenu ? _c('span', {
    staticClass: "p-absolute",
    staticStyle: {
      "top": "0",
      "right": "0"
    }
  }, [_c('IconButton', {
    attrs: {
      "color": "secondary-text",
      "icon": "mdi-dots-vertical"
    },
    on: {
      "click": _vm.onClickMenu
    }
  })], 1) : _vm._e()]), _vm._v(" "), _vm.type === "select" ? _c(VSelect, _vm._g(_vm._b({
    directives: [{
      def: Resize,
      name: "resize",
      rawName: "v-resize",
      value: _vm.updateDropdownMenuHeight,
      expression: "updateDropdownMenuHeight"
    }],
    ref: "input",
    attrs: {
      "value": _vm.value,
      "outlined": "",
      "hide-details": (_vm$attributes$hideDe = _vm.attributes.hideDetails) !== null && _vm$attributes$hideDe !== void 0 ? _vm$attributes$hideDe : !_vm.hasValidation,
      "rules": _vm.validation,
      "placeholder": (_vm$attributes$placeh = _vm.attributes.placeholder) !== null && _vm$attributes$placeh !== void 0 ? _vm$attributes$placeh : _vm.$t('placeholder.select'),
      "menu-props": _vm.dropdownMenuProps,
      "clearable": (_vm$attributes$cleara = _vm.attributes.clearable) !== null && _vm$attributes$cleara !== void 0 ? _vm$attributes$cleara : !_vm.required
    },
    on: {
      "mousedown": _vm.updateDropdownMenuHeight
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function fn(slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'v-select', _vm.attributes, false), _vm.$listeners), [_vm._l(_vm.$slots, function (_, name) {
    return _c('template', {
      slot: name
    }, [_vm._t(name)], 2);
  })], 2) : _vm.type === "autocomplete" ? _c(VAutocomplete, _vm._g(_vm._b({
    directives: [{
      def: Resize,
      name: "resize",
      rawName: "v-resize",
      value: _vm.updateDropdownMenuHeight,
      expression: "updateDropdownMenuHeight"
    }],
    ref: "input",
    attrs: {
      "value": _vm.value,
      "outlined": "",
      "hide-details": (_vm$attributes$hideDe2 = _vm.attributes.hideDetails) !== null && _vm$attributes$hideDe2 !== void 0 ? _vm$attributes$hideDe2 : !_vm.hasValidation,
      "rules": _vm.validation,
      "placeholder": (_vm$attributes$placeh2 = _vm.attributes.placeholder) !== null && _vm$attributes$placeh2 !== void 0 ? _vm$attributes$placeh2 : _vm.$t('placeholder.select'),
      "menu-props": _vm.dropdownMenuProps,
      "clearable": (_vm$attributes$cleara2 = _vm.attributes.clearable) !== null && _vm$attributes$cleara2 !== void 0 ? _vm$attributes$cleara2 : !_vm.required
    },
    on: {
      "mousedown": _vm.updateDropdownMenuHeight
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function fn(slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'v-autocomplete', _vm.attributes, false), _vm.$listeners), [_vm._l(_vm.$slots, function (_, name) {
    return _c('template', {
      slot: name
    }, [_vm._t(name)], 2);
  })], 2) : _vm.type === "combobox" ? _c(VCombobox, _vm._g(_vm._b({
    directives: [{
      def: Resize,
      name: "resize",
      rawName: "v-resize",
      value: _vm.updateDropdownMenuHeight,
      expression: "updateDropdownMenuHeight"
    }],
    ref: "input",
    attrs: {
      "value": _vm.value,
      "outlined": "",
      "hide-details": (_vm$attributes$hideDe3 = _vm.attributes.hideDetails) !== null && _vm$attributes$hideDe3 !== void 0 ? _vm$attributes$hideDe3 : !_vm.hasValidation,
      "rules": _vm.validation,
      "placeholder": (_vm$attributes$placeh3 = _vm.attributes.placeholder) !== null && _vm$attributes$placeh3 !== void 0 ? _vm$attributes$placeh3 : _vm.$t('placeholder.select'),
      "menu-props": _vm.dropdownMenuProps,
      "clearable": (_vm$attributes$cleara3 = _vm.attributes.clearable) !== null && _vm$attributes$cleara3 !== void 0 ? _vm$attributes$cleara3 : !_vm.required
    },
    on: {
      "mousedown": _vm.updateDropdownMenuHeight
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function fn(slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'v-combobox', _vm.attributes, false), _vm.$listeners), [_vm._l(_vm.$slots, function (_, name) {
    return _c('template', {
      slot: name
    }, [_vm._t(name)], 2);
  })], 2) : _vm.type === "multiLine" ? _c('AutoResizeTextArea', _vm._g(_vm._b({
    ref: "input",
    attrs: {
      "value": _vm.value,
      "outlined": "",
      "hide-details": (_vm$attributes$hideDe4 = _vm.attributes.hideDetails) !== null && _vm$attributes$hideDe4 !== void 0 ? _vm$attributes$hideDe4 : !_vm.hasValidation,
      "rules": _vm.validation
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function fn(slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'AutoResizeTextArea', _vm.attributes, false), _vm.$listeners), [_vm._l(_vm.$slots, function (_, name) {
    return _c('template', {
      slot: name
    }, [_vm._t(name)], 2);
  })], 2) : _c(VTextField, _vm._g(_vm._b({
    ref: "input",
    attrs: {
      "value": _vm.value,
      "type": _vm.type,
      "outlined": "",
      "hide-details": (_vm$attributes$hideDe5 = _vm.attributes.hideDetails) !== null && _vm$attributes$hideDe5 !== void 0 ? _vm$attributes$hideDe5 : !_vm.hasValidation,
      "rules": _vm.validation
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function fn(slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'v-text-field', _vm.attributes, false), _vm.$listeners), [_vm._l(_vm.$slots, function (_, name) {
    return _c('template', {
      slot: name
    }, [_vm._t(name)], 2);
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
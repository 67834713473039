var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('section', {
    staticClass: "content-area",
    class: {
      border: _vm.border,
      'my-4': !_vm.noMargin,
      'pa-6': !_vm.noPadding,
      'no-back': _vm.transparent
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
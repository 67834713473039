/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1f839820"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/code/src/components/input/Input.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,CustomizableHeaderTable: require('/code/src/components/table/CustomizableHeaderTable.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default})

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { VDataFooter, VSelect } from 'vuetify/lib';
export default VDataFooter.extend({
  methods: _objectSpread(_objectSpread({}, VDataFooter.options.methods), {}, {
    genItemsPerPageSelect: function genItemsPerPageSelect() {
      var _this = this;
      var value = this.options.itemsPerPage;
      var computedIPPO = this.computedDataItemsPerPageOptions;
      if (computedIPPO.length <= 1) return null;
      if (!computedIPPO.find(function (ippo) {
        return ippo.value === value;
      })) value = computedIPPO[0];
      return this.$createElement('div', {
        staticClass: 'v-data-footer__select'
      }, [this.$createElement(VSelect, {
        staticClass: 'small',
        props: {
          disabled: this.disableItemsPerPage,
          items: computedIPPO,
          value: value,
          hideDetails: true,
          auto: true,
          minWidth: '75px'
        },
        on: {
          input: this.onChangeItemsPerPage
        },
        scopedSlots: {
          selection: function selection(data) {
            return _this.$createElement('div', {
              staticClass: 'text-body-2'
            }, [data.item.value > 0 ? _this.$t('dataFooter.displayedItems', [data.item.value]) : _this.$t('dataFooter.displayedAll')]);
          }
        }
      })]);
    },
    genPaginationInfo: function genPaginationInfo() {
      return null;
    },
    genIcons: function genIcons() {
      var before = [];
      var after = [];
      before.push(this.genIcon(this.onPreviousPage, this.options.page === 1, this.$t('dataFooter.prevPage'), this.$vuetify.rtl ? this.nextIcon : this.prevIcon));
      after.push(this.genIcon(this.onNextPage, this.disableNextPageIcon, this.$t('dataFooter.nextPage'), this.$vuetify.rtl ? this.prevIcon : this.nextIcon));
      if (this.showFirstLastPage) {
        before.unshift(this.genIcon(this.onFirstPage, this.options.page === 1, this.$t('dataFooter.firstPage'), this.$vuetify.rtl ? this.lastIcon : this.firstIcon));
        after.push(this.genIcon(this.onLastPage, this.options.page >= this.pagination.pageCount || this.options.itemsPerPage === -1, this.$t('dataFooter.lastPage'), this.$vuetify.rtl ? this.firstIcon : this.lastIcon));
      }
      var children = ['–'];
      var itemsLength = this.pagination.itemsLength;
      var pageStart = this.pagination.pageStart;
      var pageStop = this.pagination.pageStop;
      if (this.pagination.itemsLength && this.pagination.itemsPerPage) {
        pageStart = this.pagination.pageStart + 1;
        pageStop = itemsLength < this.pagination.pageStop || this.pagination.pageStop < 0 ? itemsLength : this.pagination.pageStop;
        children = this.$scopedSlots['page-text'] ? [this.$scopedSlots['page-text']({
          pageStart: pageStart,
          pageStop: pageStop,
          itemsLength: itemsLength
        })] : [this.$t('dataFooter.pageText', [pageStart, pageStop, itemsLength])];
      } else if (this.$scopedSlots['page-text']) {
        children = [this.$scopedSlots['page-text']({
          pageStart: pageStart,
          pageStop: pageStop,
          itemsLength: itemsLength
        })];
      }
      return [this.$createElement('div', {
        staticClass: 'v-data-footer__icons-before'
      }, before), this.$createElement('div', {
        staticClass: 'text-body-2'
      }, children), this.$createElement('div', {
        staticClass: 'v-data-footer__icons-after'
      }, after)];
    }
  })
});
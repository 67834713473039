import "core-js/modules/es.symbol.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { VTextarea, VCombobox } from 'vuetify/lib';
import { merge } from 'lodash-es';
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: _objectSpread(_objectSpread(_objectSpread({}, VTextarea.options.props), VCombobox.options.props), {}, {
    value: {
      type: [Object, String, Number, Array],
      default: ''
    },
    initialValue: {
      type: [Object, String, Number, Array],
      default: undefined
    },
    label: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: null
    },
    warningRules: {
      type: Array,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    validateOnMount: {
      type: Boolean,
      default: true
    }
  }),
  data: function data() {
    return {
      warningMessages: [],
      focus: false,
      dropdownMenuMaxHeight: 500
    };
  },
  computed: {
    dropdownMenuProps: function dropdownMenuProps() {
      return _objectSpread(_objectSpread({}, this.menuProps), {}, {
        offsetY: true,
        offsetOverflow: false,
        maxHeight: this.dropdownMenuMaxHeight,
        allowOverflow: true
      });
    },
    attributes: function attributes() {
      var _this$$refs$input;
      var attr = _objectSpread(_objectSpread(_objectSpread({
        hideDetails: 'auto'
      }, this.$attrs), this.$options.propsData), {}, {
        label: null,
        type: null,
        warningRules: null
      });
      if (this.hasWarning && (_this$$refs$input = this.$refs.input) !== null && _this$$refs$input !== void 0 && _this$$refs$input.valid) {
        attr = merge(attr, {
          messages: this.warningMessages,
          class: ['v-input--has-state', 'warning--text']
        });
      }
      return attr;
    },
    hasWarning: function hasWarning() {
      return this.warningMessages.length > 0;
    },
    valid: {
      get: function get() {
        var _this$$refs$input2;
        return (_this$$refs$input2 = this.$refs.input) === null || _this$$refs$input2 === void 0 ? void 0 : _this$$refs$input2.valid;
      },
      set: function set(val) {
        if (this.$refs.input) {
          this.$refs.input.valid = val;
        }
      }
    },
    hasValidation: function hasValidation() {
      return this.validation.length >= 1;
    },
    validation: function validation() {
      var _this = this,
        _this$rules,
        _this$warningRules;
      return [this.required ? function (v) {
        return !!v || _this.$t('error.required');
      } : null].concat(_toConsumableArray((_this$rules = this.rules) !== null && _this$rules !== void 0 ? _this$rules : []), [(_this$warningRules = this.warningRules) !== null && _this$warningRules !== void 0 && _this$warningRules.length ? function (text) {
        // バリデーションタイミングでWarning判定をする（Warningはバリデーションエラーにはしないのでtrueを返す）
        _this.checkWarning(text);
        return true;
      } : null]).filter(function (item) {
        return item != null;
      });
    }
  },
  mounted: function mounted() {
    var _this$value;
    // 初期値が設定されている場合に初期バリデーションを実行する
    if (this.$refs.input && this.validateOnMount && ((_this$value = this.value) === null || _this$value === void 0 ? void 0 : _this$value.length) > 0) {
      this.$refs.input.hasInput = true;
    }
  },
  methods: {
    checkWarning: function checkWarning(value) {
      var _this$warningRules2;
      var errorBucket = [];
      (_this$warningRules2 = this.warningRules) === null || _this$warningRules2 === void 0 ? void 0 : _this$warningRules2.forEach(function (rule) {
        var valid = typeof rule === 'function' ? rule(value) : rule;
        if (valid === false || typeof valid === 'string') {
          errorBucket.push(valid || '');
        }
      });
      this.warningMessages = errorBucket;
    },
    validate: function validate() {
      var _this$$refs$input3, _this$$refs$input3$va;
      return (_this$$refs$input3 = this.$refs.input) === null || _this$$refs$input3 === void 0 ? void 0 : (_this$$refs$input3$va = _this$$refs$input3.validate) === null || _this$$refs$input3$va === void 0 ? void 0 : _this$$refs$input3$va.call(_this$$refs$input3);
    },
    reset: function reset() {
      var _this$$refs$input4, _this$$refs$input4$re;
      (_this$$refs$input4 = this.$refs.input) === null || _this$$refs$input4 === void 0 ? void 0 : (_this$$refs$input4$re = _this$$refs$input4.reset) === null || _this$$refs$input4$re === void 0 ? void 0 : _this$$refs$input4$re.call(_this$$refs$input4);
    },
    onClickMenu: function onClickMenu(e) {
      var _this$$refs$input5, _this$$refs$input5$$r, _this$$refs$input5$$r2, _this$$refs$input5$$r3, _this$$refs$input6, _this$$refs$input6$$e;
      (_this$$refs$input5 = this.$refs.input) === null || _this$$refs$input5 === void 0 ? void 0 : (_this$$refs$input5$$r = _this$$refs$input5.$refs) === null || _this$$refs$input5$$r === void 0 ? void 0 : (_this$$refs$input5$$r2 = _this$$refs$input5$$r.input) === null || _this$$refs$input5$$r2 === void 0 ? void 0 : (_this$$refs$input5$$r3 = _this$$refs$input5$$r2.focus) === null || _this$$refs$input5$$r3 === void 0 ? void 0 : _this$$refs$input5$$r3.call(_this$$refs$input5$$r2);
      (_this$$refs$input6 = this.$refs.input) === null || _this$$refs$input6 === void 0 ? void 0 : (_this$$refs$input6$$e = _this$$refs$input6.$emit) === null || _this$$refs$input6$$e === void 0 ? void 0 : _this$$refs$input6$$e.call(_this$$refs$input6, 'contextmenu', new MouseEvent('contextmenu', {
        bubbles: true,
        clientX: e.clientX,
        clientY: e.clientY
      }));
    },
    updateDropdownMenuHeight: function updateDropdownMenuHeight() {
      var _this$$refs$input7;
      var input = (_this$$refs$input7 = this.$refs.input) === null || _this$$refs$input7 === void 0 ? void 0 : _this$$refs$input7.$el;
      if (!input) return;
      var rect = input.getBoundingClientRect();
      this.dropdownMenuMaxHeight = Math.max(Math.min(window.innerHeight - rect.bottom - 40, 500), rect.height * 2);
    }
  }
};
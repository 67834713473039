import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.number.constructor.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { VTextarea } from 'vuetify/lib';
export default VTextarea.extend({
  props: _objectSpread(_objectSpread({}, VTextarea.options.props), {}, {
    autoGrow: undefined,
    maxRows: {
      type: [String, Number],
      default: null
    }
  }),
  watch: {
    value: function value(newValue, oldValue) {
      var _this = this;
      if ((newValue === null || newValue === void 0 ? void 0 : newValue.length) < (oldValue === null || oldValue === void 0 ? void 0 : oldValue.length)) {
        // 文字数が減っている場合は一旦状態をリセットする
        var textarea = this.$refs.input;
        if (textarea) textarea.style.height = null;
        this.manualyResized = false;
      }
      this.$nextTick(function () {
        _this.adjustTextFieldSize();
      });
    }
  },
  data: function data() {
    var _this2 = this;
    return {
      resizeObserver: new ResizeObserver(function () {
        _this2.$nextTick(function () {
          _this2.resizeTextField();
        });
      }),
      manualyResized: false
    };
  },
  mounted: function mounted() {
    var _this$resizeObserver;
    this.adjustTextFieldSize();
    (_this$resizeObserver = this.resizeObserver) === null || _this$resizeObserver === void 0 ? void 0 : _this$resizeObserver.observe(this.$refs.input);
  },
  beforeDestroy: function beforeDestroy() {
    var _this$resizeObserver2;
    (_this$resizeObserver2 = this.resizeObserver) === null || _this$resizeObserver2 === void 0 ? void 0 : _this$resizeObserver2.unobserve(this.$refs.input);
  },
  methods: {
    adjustTextFieldSize: function adjustTextFieldSize() {
      var textarea = this.$refs.input;
      if (!textarea) return;
      var maxRows = parseInt(this.maxRows, 10);
      var maxHeight = Number.MAX_VALUE;
      if (maxRows) {
        maxHeight = parseInt(maxRows, 10) * parseFloat(this.rowHeight);
      }
      var textHeight = textarea.scrollHeight;
      if (textHeight === 0) {
        var _this$value$split$len, _this$value, _this$value$split;
        textHeight = (((_this$value$split$len = (_this$value = this.value) === null || _this$value === void 0 ? void 0 : (_this$value$split = _this$value.split('\n')) === null || _this$value$split === void 0 ? void 0 : _this$value$split.length) !== null && _this$value$split$len !== void 0 ? _this$value$split$len : 0) + 1) * parseFloat(this.rowHeight);
      }
      var height = Math.min(maxHeight, textHeight);
      if (height > parseFloat(textarea.style.height) || !this.manualyResized) {
        textarea.style.height = "".concat(height, "px");
        this.manualyResized = false;
      }
    },
    resizeTextField: function resizeTextField() {
      this.manualyResized = true;
    },
    genInput: function genInput() {
      var input = VTextarea.options.methods.genInput.call(this);
      input.data.on.resize = this.resizeTextField;
      return input;
    },
    onInput: function onInput(e) {
      VTextarea.options.methods.onInput.call(this, e);
      this.adjustTextFieldSize();
    }
  }
});